/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, ContactForm } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="mt--40 pb--80 pt--0" name={"xi6rhmztau"}>
          
          <ColumnWrap className="column__flex el--1 pb--0 pt--0" anim={null} style={{"maxWidth":1410}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Title className="title-box pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}} content={"Kontakt"}>
              </Title>

              <Image className="--left pb--02 pl--02 pr--02 pt--02" alt={""} src={"https://cdn.swbpg.com/o/11591/f54a559b8f5e4d9086b55732bbd82975.svg"} svg={false} href={"/de/"} sizes={"100vw"} style={{"maxWidth":36}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"1zv03r3aiu9i"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l30"}>
        </Column>


        <Column className="pb--0 pt--0" name={"t9vs411mav"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --right el--2 mb--20 pl--0 pr--0 flex--top" anim={"2"} animS={"4"} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/103/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":640}}>
              
              <Image className="--right" alt={""} src={"https://cdn.swbpg.com/o/11591/800aa876a7894f4a8a9f381ae403dbb0.svg"} svg={false} href={"https://www.facebook.com/saywebpage"} sizes={"100vw"} style={{"maxWidth":32}} srcSet={""} content={null}>
              </Image>

              <Image className="--right" alt={""} src={"https://cdn.swbpg.com/o/11591/635bf074f39b4e9baac74cbac566f176.svg"} svg={false} href={"http://instagram.com/saywebpage"} sizes={"100vw"} style={{"maxWidth":32}} srcSet={""} content={null}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--left fs--43" style={{"maxWidth":410}} content={"Ivan Kräuslich"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":410}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left lh--16" style={{"maxWidth":410}} content={"+49 797 811 2X05<br>info@vase-stranky.com"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--80 pb--80 pt--80" name={"3sw7onf802q"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 el--2 pb--30 pt--25 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1410}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box pl--0" content={"<span style='color: var(--color-dominant)'>Schreiben Sie uns ...</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":590}} content={"<span style='color: var(--color-dominant)'><a href=\"https://saywebpage.com\">Webproduktion stránek</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 --right fs--20" action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"E-Mail Kontakt:","type":"email","required":true,"placeholder":"Bitte geben Sie Ihre e-Mail Adresse ein"},{"name":"Telefon Nr.","type":"text","placeholder":"Telefon Nr."},{"name":"Nachricht","type":"textarea","required":true,"placeholder":"Bitte geben Sie Text Ihrer Nachricht ein"},{"name":"Versenden","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}